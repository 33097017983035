<template>
  <div>
    <DepartmentLiquidation
      :title="'DEPARTMENT EXPENSE'"
    />
  </div>
</template>
<script>
  import DepartmentLiquidation
    from '@/views/input_interface/monthly_liquidation/FundLiquidation'

  export default {
    components: {
      DepartmentLiquidation,
    },
  }
</script>
